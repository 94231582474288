

















































































































import Vue from 'vue';
import { Contacto, Mensagem, ParticipanteChamada } from '@/typings/typings';

export default Vue.extend({
  props: {
    isVisible: {
      type: Object as () => boolean,
    },
    chat: {
      type: Object as () => Contacto,
    },
  },
  data: () => ({
    imagensJogo: [
      {
        href: 'img/jogos/4linha.png',
        ativo: false,
        tooltip: '4 em Linha',
      },
      {
        href: 'img/jogos/damas.jpg',
        ativo: false,
        tooltip: 'Damas',
      },
      {
        href: 'img/jogos/galo.png',
        ativo: false,
        tooltip: 'Jogo do Galo',
      },
      {
        href: 'img/jogos/xadrez.png',
        ativo: false,
        tooltip: 'Xadrez',
      },
    ],
    selecionado: '',
    erroSelecionado: '',
    informacoesSelecionado: '',
    contactos: [] as ParticipanteChamada[],
  }),
  computed: {
    jogoAtivo(): { tooltip: string, href: string, ativo: boolean } | undefined {
      return this.imagensJogo.find((i) => i.ativo);
    },
  },
  watch: {
    chat: {
      immediate: true,
      handler(newChat) {
        this.contactos = newChat.naChamada
          .sort((a: ParticipanteChamada, b: ParticipanteChamada) => {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;

            return 0;
          });

        this.resetFields();
        if (this.contactos.length === 1) {
          this.selecionado = this.contactos[0].nome;
          this.erroSelecionado = '';
        }
      },
    },
  },
  methods: {
    errosSelecionado() {
      this.informacoesSelecionado = '';
      if (!this.selecionado) this.erroSelecionado = 'Contacto obrigatório';
      else this.erroSelecionado = '';
    },
    resetFields() {
      this.selecionado = '';
      this.erroSelecionado = '';
      this.informacoesSelecionado = 'Contacto obrigatório';

      for (let i = 0; i < this.imagensJogo.length; i += 1) { this.imagensJogo[i].ativo = false; }
    },
    imagemClick(imagem: { href: string, ativo: boolean }): void {
      // Meter imagem ativa
      for (let i = 0; i < this.imagensJogo.length; i += 1) {
        if (this.imagensJogo[i].href === imagem.href) {
          this.imagensJogo[i].ativo = true;
        } else {
          this.imagensJogo[i].ativo = false;
        }
      }
    },
    contactosErrors() {
      if (!this.selecionado) this.erroSelecionado = 'Contacto obrigatório';
      else this.erroSelecionado = '';
    },
    closeDialog(): void {
      this.resetFields();
      this.$emit('close');
    },
    submeter() {
      const jogo = this.jogoAtivo;
      this.$store.dispatch('contactos/sendMessage', {
        chat: this.chat,
        mensagem: {
          autor: 'Jogo',
          texto: `<strong>${this.$store.state.user.contacto.nome}</strong> começou um jogo de <strong>${jogo?.tooltip}</strong> com <strong>${this.selecionado}</strong>.`,
          momento: new Date(),
          imagem: jogo?.href,
          sistema: true,
        } as Mensagem,
      });

      this.$emit('iniciado');
      this.closeDialog();
      this.resetFields();
    },
  },
});
