














































































































































































































































































































































































import Vue from 'vue';
import CriarGrupo from '@/components/dialogs/CriarGrupo.vue';
import { Contacto, Mensagem } from '@/typings/typings';
import NavBar from '@/components/NavBar.vue';

export default Vue.extend({
  components: {
    NavBar,
    CriarGrupo,
  },
  data: () => ({
    dialogCriarGrupo: false,
    reRenderTable: true,
    filtroGrupos: false,
    pesquisaMensagem: '',
    recentesSearch: '',
    recenteSeleccionado: null as Contacto | null,
    chamadaTooltip: false,
    comCamara: false,
  }),
  beforeMount() {
    // Carregar chamada se tiver ido ao help ou feito refresh
    if (this.$route.name === 'Call') {
      const chamada: Contacto = this.$store.state.user.contacto.emChamada;
      if (chamada) {
        this.recenteSeleccionado = this.$store.state.contactos.recentes
          .find((c: Contacto) => c.nome === chamada.nome);
      }
    }
  },
  computed: {
    emChamada() {
      return this.recenteSeleccionado?.nome === this.$store.state.user.contacto.emChamada?.nome;
    },
    computedRecentes(): Contacto[] {
      this.forceRerenderTable();
      const query = this.recentesSearch.toLowerCase();

      return this.$store.state.contactos.recentes
        .filter((r: Contacto) => {
          let valid = !r.hidden;
          if (valid && this.recentesSearch) { valid = r.nome.toLowerCase().includes(query); }
          if (valid && this.filtroGrupos) { valid = !!r.grupo; }

          return valid;
        })
        // eslint-disable-next-line no-nested-ternary
        .map((r: Contacto) => ({ ...r, lastIteraction: r.mensagens?.length ? r.mensagens[r.mensagens.length - 1]?.momento?.getTime() : (r.createdAt instanceof Date ? r.createdAt.getTime() : 0) }))
        .sort((a: Contacto & { lastIteraction: number }, b: Contacto & { lastIteraction: number }) => b.lastIteraction - a.lastIteraction);
    },
    computedContactosGrupo() {
      if (!this.recenteSeleccionado) return [];
      console.log(this.recenteSeleccionado.naChamada);
      return [...this.recenteSeleccionado.naChamada]
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;

          return 0;
        })
        .map((c) => c.nome)
        .join(', ');
    },
  },
  methods: {
    minifyName(nome: string, eGrupo: boolean, emChamada: boolean) {
      let cnt = 0;
      if (eGrupo) cnt += 3;
      if (emChamada) cnt += 3;

      return nome.length >= 15 - cnt ? `${nome.slice(0, 13 - cnt)}...` : nome;
    },
    openCriarGrupo() {
      this.dialogCriarGrupo = true;
    },
    closeCriarGrupo(): void {
      this.dialogCriarGrupo = false;
    },
    selecionarUltimo(): void {
      if (this.computedRecentes.length) { [this.recenteSeleccionado] = this.computedRecentes; }
    },
    selecionar(sentido: number) {
      if (!this.recenteSeleccionado) return;

      const idx = this.computedRecentes.findIndex((c) => c.nome === this.recenteSeleccionado?.nome);
      if ((idx === 0 && sentido < 0)
        || (idx === this.computedRecentes.length - 1 && sentido > 0)) { return; }

      this.recenteSeleccionado = this.computedRecentes[idx + sentido];
    },
    getAvatar(nome: string) {
      return this.$store.state.contactos.avatars[nome];
    },
    forceRerenderTable() {
      this.reRenderTable = false;

      this.$nextTick().then(() => {
        this.reRenderTable = true;
      });
    },
    terminarChamada() {
      this.$store.dispatch('contactos/setLastCallDuration', this.recenteSeleccionado);
      this.$store.dispatch('user/leaveCall');
    },
    entraChamada(force: boolean, camera: boolean, jaNaChamada: boolean) {
      if (!force && (this.$store.state.user.contacto.emChamada?.nome && this.$store.state.user.contacto.emChamada?.nome !== this.recenteSeleccionado?.nome)) {
        this.chamadaTooltip = true;
        this.comCamara = camera;
        return;
      }
      this.chamadaTooltip = false;

      setTimeout(() => {
        this.forceRerenderTable();

        if (!jaNaChamada) {
          this.$store.dispatch('contactos/sendMessage', {
            chat: this.recenteSeleccionado,
            mensagem: {
              autor: 'Chamada',
              sistema: true,
              momento: new Date(),
              texto: `<strong>${this.$store.state.user.contacto.nome}</strong> começou uma ${camera ? 'video' : ''}chamada.`,
              chamada: {
                inicio: new Date(),
              },
            } as Mensagem,
          });

          if (this.$store.state.user.contacto.emChamada?.nome !== this.recenteSeleccionado?.nome) {
            this.$store.dispatch('contactos/setLastCallDuration', this.$store.state.user.contacto.emChamada);
          }
        }

        this.$store.dispatch('user/joinCall', this.recenteSeleccionado);
        if (!!this.$store.state.user.chamada.imagem !== camera) { this.$store.dispatch('user/toggleImage'); }
        this.$router.push({ name: 'Call' });
      }, 10);
    },
  },
});
