
































































































































































import Vue from 'vue';
import { groupBy } from '@/utils';
import { Mensagem } from '@/typings/typings';

export default Vue.extend({
  props: {
    mensagens: {
      type: Object as () => Mensagem[],
    },
  },
  methods: {
    parseAutor(autor: string): string {
      if (autor === '$$user$$') { return this.$store.state.user.contacto.nome; }
      return autor;
    },
    getHoras(data: Date) {
      const d = data.toTimeString();
      return d.slice(0, d.indexOf(':') + 3);
    },
  },
  computed: {
    computedMensagens() {
      if (!this.mensagens.length) return [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return groupBy(this.mensagens as Record<string, any>[], 'autor');
    },
  },
});
