



















































import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    nome: '',
    erro: '',
    informacao: 'Nome obrigatório',
  }),
  beforeCreate() {
    // Limpar sessionStorage. é por domínio, portanto não queremos lixo de outros projetos
    sessionStorage.clear();
  },
  mounted() {
    if (sessionStorage.getItem('user')) {
      this.nome = this.$store.state.user.contacto.nome;
      this.erro = '';
    }

    const inp = (this.$refs.inpNome as Vue).$el.querySelector('input');
    if (inp) { inp.focus(); }
  },
  methods: {
    iniciarSessao() {
      this.$store.dispatch('user/setUsername', this.nome);
      this.$router.push({ name: 'Chat' });
    },
    checkSubmit({ code }: KeyboardEvent) {
      if (code === 'Enter' && !this.erro) this.iniciarSessao();
    },
    checkErro() {
      this.informacao = '';
      if (!this.nome) this.erro = 'Nome obrigatório';
      else if (this.nome.length > 20) this.erro = 'Comprimento máximo: 20 caracteres';
      else this.erro = '';
    },
  },
});
