





































import Vue from 'vue';

export default Vue.extend({
  props: {
    isVisible: {
      type: Object as () => boolean,
    },
  },
  methods: {
    submit() {
      // this.$store.dispatch('contactos/createGroup', { nome: this.nome, contactos: this.selecionados });

      this.$emit('signout');
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('close');
    },
  },
});
