
















































































































































































/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

import Vue from 'vue';
import { ParticipanteChamada, Contacto } from '@/typings/typings';
import AlterarFundo from '@/components/dialogs/AlterarFundo.vue';
import Soundboard from '@/components/dialogs/Soundboard.vue';

export default Vue.extend({
  components: {
    AlterarFundo,
    Soundboard,
  },
  props: {
    chat: {
      type: Object as () => Contacto,
    },
  },
  data: () => ({
    userVideo: true,
    userMicro: true,
    dialogFundos: false,
    dialogSoundboard: false,
    loadedImages: {} as Record<string, boolean>,
  }),
  computed: {
    emChamada(): ParticipanteChamada[] {
      if (!this.chat.naChamada?.length) return [];
      return [...this.chat.naChamada, this.$store.state.user.chamada];
    },
  },
  watch: {
    chat: {
      immediate: true,
      handler(val: Contacto, oldVal: Contacto) {
        // Go to chat if changes from one chat to another
        if (val != null && oldVal != null) {
          this.$router.push({ name: 'Chat' });
          return;
        }

        this.resetLoadedImages();
      },
    },
  },
  beforeMount() {
    this.userVideo = !!this.$store.state.user.chamada.imagem;
    this.userMicro = this.$store.state.user.chamada.micro;
  },
  methods: {
    getContactoImage(nome: string) {
      if (nome === '$$user$$') return this.$store.state.user.chamada.imagem || this.$store.getters['contactos/getAvatar'](nome);
      return this.$store.getters['contactos/getCamera'](nome, this.chat.nome) || this.$store.getters['contactos/getAvatar'](nome);
    },
    parseNome(nome: string) {
      return nome.replace('$$user$$', this.$store.state.user.contacto.nome);
    },
    toggleUserVideo() {
      this.$store.dispatch('user/toggleImage');
      this.$emit('cameraUpdate');
    },
    toggleUserMicro() {
      this.$store.dispatch('user/toggleMicro');
    },
    setLoadedImage(nome: string) {
      this.$set(this.loadedImages, nome, true);
    },
    resetLoadedImages() {
      this.loadedImages = {};
    },
    leaveCall() {
      this.$store.dispatch('contactos/setLastCallDuration', this.chat);

      this.$emit('callEnd');
      this.$store.dispatch('user/leaveCall');
      this.$router.push({ name: 'Chat' });
    },
    openDialogFundos() {
      this.dialogFundos = true;
    },
    closeDialogFundos() {
      this.dialogFundos = false;
    },
    openDialogSoundboard() {
      this.dialogSoundboard = true;
    },
    closeDialogSoundboard() {
      this.dialogSoundboard = false;
    },
  },
});
