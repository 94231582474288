































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    isVisible: {
      type: Object as () => boolean,
    },
  },
  data: () => ({
    imagensFundo: [
      {
        href: 'img/fundos/vulcao.png',
        ativo: false,
        tooltip: 'Vulcão',
      },
      {
        href: 'img/fundos/peixes.jpg',
        ativo: false,
        tooltip: 'Peixes',
      },
      {
        href: 'img/fundos/praia.jpg',
        ativo: false,
        tooltip: 'Praia',
      },
      {
        href: 'img/fundos/leiria.jpg',
        ativo: false,
        tooltip: 'Leiria',
      },
    ],
  }),
  computed: {
    fundoAtivo(): { href: string, ativo: boolean } | undefined {
      return this.imagensFundo.find((i) => i.ativo);
    },
    getUserImage() {
      return this.$store.state.user.chamada.imagem || 'img/linus_nobg.png';
    },
  },
  methods: {
    imagemClick(imagem: { href: string, ativo: boolean }): void {
      // Meter imagem ativa
      for (let i = 0; i < this.imagensFundo.length; i += 1) {
        if (this.imagensFundo[i].href === imagem.href) {
          this.imagensFundo[i].ativo = true;
        } else {
          this.imagensFundo[i].ativo = false;
        }
      }
    },

    closeDialog(): void {
      this.$emit('close');
    },
    reporFundo(): void {
      this.$store.dispatch('user/resetBackground');
      for (let i = 0; i < this.imagensFundo.length; i += 1) {
        this.imagensFundo[i].ativo = false;
      }

      this.closeDialog();
    },
    submeterFundo(): void {
      this.$store.dispatch('user/setBackground', this.fundoAtivo?.href);
      this.closeDialog();
    },
  },
});
