



































































import Vue from 'vue';
import ConfirmarSaida from './dialogs/ConfirmarSaida.vue';

export default Vue.extend({
  components: { ConfirmarSaida },
  data() {
    return {
      primaryColor: '',
      dialogConfirmarSaida: false,
      lightMode: false,
    };
  },
  beforeMount() {
    this.lightMode = localStorage.getItem('vsTheme') === 'light';
    this.primaryColor = sessionStorage.getItem('vsPrimary') || '#7289DA';
  },
  methods: {
    changeTheme() {
      this.$vs.toggleTheme(this.lightMode ? 'light' : 'dark');
    },
    updatePrimary() {
      this.$vs.setColor('primary', this.primaryColor);
      sessionStorage.setItem('vsPrimary', this.primaryColor);
    },
    goHome() {
      if (this.$route.name !== 'Chat') this.$router.push({ name: 'Chat' });
      this.$emit('goHome');
    },
    goHelp() {
      if (this.$route.name !== 'Help') this.$router.push({ name: 'Help', params: { p: this.$route.name as string } });
    },
    openConfirmarSaida() {
      this.dialogConfirmarSaida = true;
    },
    closeConfirmarSaida() {
      this.dialogConfirmarSaida = false;
    },
    submitGroupCreate(): void {
      // todo group create submit
    },
    async signout() {
      await this.$store.dispatch('user/reset');
      await this.$store.dispatch('contactos/reset');
      await this.$router.push({ name: 'Login' });
      window.location.reload();
    },
  },
});

