































import Vue from 'vue';

export default Vue.extend({
  created() {
    console.log('Hola que hace');
    console.log('a mi me gusta la gasolina');

    const lightMode = localStorage.getItem('vsTheme') === 'light';
    this.$vs.setTheme(lightMode ? 'light' : 'dark');

    // Login pela primeira vez
    if (this.$route.name !== 'Login' && !sessionStorage.getItem('user')) {
      this.$router.push({ name: 'Login' });
      return;
    }

    // Carregar contactos (inclui mensagens) da sessionStorage
    this.$store.dispatch('contactos/fetch');
    // Carregar definições utilizador
    this.$store.dispatch('user/fetch');
  },
  mounted() {
    // this.ataque();
    const primaryColor = sessionStorage.getItem('vsPrimary') || '#7289DA';
    this.$vs.setColor('primary', primaryColor);
    sessionStorage.setItem('vsPrimary', primaryColor);
  },
  methods: {
    getCor() {
      return Math.floor(Math.random() * 16777215).toString(16);
    },
    ataque() {
      const el = document.getElementById('cont');
      if (el) {
        setInterval(() => {
          el.style.backgroundColor = `#${this.getCor()}`;
        }, 500);
      }
    },
  },
});
