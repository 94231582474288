








































































































import Vue from 'vue';
import Messages from '@/components/Messages.vue';
import { Mensagem, Contacto } from '@/typings/typings';
import Jogos from '@/components/dialogs/Jogos.vue';

export default Vue.extend({
  components: {
    Jogos,
    Messages,
  },
  props: {
    chat: {
      type: Object as () => Contacto,
    },
    search: {
      type: Object as () => string,
    },
  },
  watch: {
    chat: {
      immediate: true,
      handler() {
        this.forceRerenderChat();
        this.descerMensagem();
      },
    },
  },
  data: () => ({
    dialogJogos: false,
    reRenderChat: true,
    ficheirosDisplay: false,
    mensagem: '',
    ficheiro: null,
  }),
  mounted() {
    this.descerMensagem();
  },
  computed: {
    computedMensagens(): Mensagem[] {
      const chat = this.getChat();
      if (!chat || !chat.mensagens) return [];
      if (!this.search) return chat.mensagens;
      return chat.mensagens.filter((m: Mensagem) => (m.texto ? m.texto.toLowerCase().includes(this.search.toLowerCase()) : false));
    },
  },
  methods: {
    openDialogJogos() {
      this.dialogJogos = true;
    },
    closeDialogJogos() {
      this.dialogJogos = false;
    },
    openFileExplorer() {
      (this.$refs.fileUpload as HTMLInputElement).click();
    },
    forceRerenderChat() {
      this.reRenderChat = false;

      this.$nextTick().then(() => {
        this.reRenderChat = true;
      });
    },
    getChat(): Contacto | null {
      return this.$store.state.contactos.recentes.find((r: Contacto) => r.nome === this.chat.nome);
    },
    uploadFicheiro({ target: { files } }: unknown) {
      this.$store.dispatch('contactos/sendMessage', {
        chat: this.chat,
        mensagem: {
          autor: '$$user$$',
          ficheiro: files[0].name,
          momento: new Date(),
        } as Mensagem,
      });

      this.$emit('messageSent');

      this.descerMensagem();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.fileUpload as HTMLInputElement).value = '';
    },
    openFicheirosDialog() {
      this.ficheirosDisplay = true;
    },
    cancelFicheirosDialog() {
      this.ficheirosDisplay = false;
      return null;
    },
    submitFicheirosDialog() {
      this.ficheirosDisplay = false;
      return null;
    },
    checkSubmit({ code }: KeyboardEvent) {
      if (code === 'Enter') this.enviarMensagem();
    },
    enviarMensagem() {
      if (this.mensagem === '') return;

      this.$store.dispatch('contactos/sendMessage', {
        chat: this.chat,
        mensagem: {
          autor: '$$user$$',
          texto: this.mensagem,
          momento: new Date(),
        } as Mensagem,
      });

      if (this.chat.nome === 'Twix') {
        // No caso da pessoa mudar de chat
        const chatCpy = { ...this.chat };
        setTimeout(() => {
          this.$store.dispatch('contactos/sendMessage', {
            chat: chatCpy,
            mensagem: {
              autor: 'Twix',
              texto: 'aoao',
              momento: new Date(),
            } as Mensagem,
          });

          if (this.chat.nome === 'Twix') { this.descerMensagem(); }
        }, 5 * 1000);
      }

      this.$emit('messageSent');

      this.mensagem = '';

      this.descerMensagem();
    },
    descerMensagem(): void {
      setTimeout(() => {
        const el = this.$refs.mensagens as Vue;
        if (el?.$el.firstElementChild?.firstElementChild?.lastElementChild?.lastElementChild?.lastElementChild) {
          el.$el.firstElementChild.firstElementChild.lastElementChild.lastElementChild.lastElementChild.scrollIntoView();
        }
      }, 50);
    },
  },
});
