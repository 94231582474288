


































































































































import Vue from 'vue';
import { Contacto } from '@/typings/typings';

export default Vue.extend({
  props: {
    isVisible: {
      type: Object as () => boolean,
    },
  },
  data: () => ({
    nome: '',
    txtEmail: '',
    selecionados: [],
    selecionadosEmail: [] as string[],
    errosNome: '',
    informacoesNome: '',
    errosEmail: '',
    informacoesEmail: '',
    errosContactos: '',
    informacoesContactos: '',
    contactos: [],
    contactosEmail: [],
  }),
  computed: {
    canSubmit() {
      return !this.errosNome && !this.informacoesNome && (this.selecionados.length || this.selecionadosEmail.length);
    },
  },
  beforeMount() {
    this.contactos = this.$store.state.contactos.recentes
      .filter((c: Contacto) => !c.grupo && !c.hidden)
      .sort((a: Contacto, b: Contacto) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;

        return 0;
      });

    this.contactosEmail = this.$store.state.contactos.recentes
      .filter((c: Contacto) => !c.grupo && !!c.email)
      .sort((a: Contacto, b: Contacto) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;

        return 0;
      });

    this.resetFields();
  },
  methods: {
    nomeErrors() {
      this.informacoesNome = '';
      if (!this.nome.length) this.errosNome = 'Nome obrigatório';
      else if (this.nome.length > 20) this.errosNome = 'Comprimento máximo: 20 caracteres';
      else if (this.$store.state.contactos.recentes.find((c: Contacto) => c.nome === this.nome)) this.errosNome = 'Já existe um grupo ou contacto com esse nome';
      else this.errosNome = '';
    },
    emailErrors() {
      this.informacoesEmail = '';
      if (!this.txtEmail.length) this.errosEmail = 'E-mail obrigatório';
      else if (this.txtEmail.length > 128) this.errosEmail = 'Comprimento máximo: 128 caracteres';
      else if (!this.emailValid(this.txtEmail)) this.errosEmail = 'Email inválido';
      else this.errosEmail = '';
    },
    contactosErrors() {
      this.informacoesContactos = '';
      if (!this.selecionados.length && !this.selecionadosEmail.length) this.errosContactos = 'Pelo menos um contacto obrigatório';
      else this.errosContactos = '';
    },
    submit() {
      this.$store.dispatch('contactos/createGroup', {
        nome: this.nome,
        contactos: [
          ...this.selecionados,
          ...this.selecionadosEmail,
        ],
      });

      this.resetFields();
      this.$emit('criado');
      this.closeDialog();
    },
    add() {
      this.selecionadosEmail.push(this.txtEmail);
    },
    resetFields() {
      this.nome = '';
      this.errosNome = '';
      this.informacoesNome = 'Nome obrigatório';
      this.txtEmail = '';
      this.errosEmail = '';
      this.informacoesEmail = 'Insira um email válido.';
      this.selecionados = [];
      this.errosContactos = '';
      this.informacoesContactos = 'Pelo menos um contacto obrigatório';
      this.selecionadosEmail = [];
    },
    closeDialog() {
      this.$emit('close');
    },
    emailValid(email: string) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    },
  },
});
